import {Component, inject, Inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../interfaces/app-state.interface';
import {ZahlungActions} from '../../store/actions/zahlung.actions';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'zlng-deleted-dialog',
  templateUrl: './deleted-dialog.component.html',
  styleUrls: ['./deleted-dialog.component.scss']
})
export class DeletedDialogComponent {

  private _store = inject(Store<AppState>);
  private _router = inject(Router);

  constructor(
    @Inject(MAT_DIALOG_DATA) private action: { inhaberId: string; zahlungId: string; type: string; },
  ) {
  }

  backToOverview(): void {
    const inhaberId = this.action.inhaberId;
    this._store.dispatch(ZahlungActions.openOverviewPage({inhaberId}));
    this._store.dispatch(ZahlungActions.closeZahlungDeletedDialog());
  }

  newPayment(): void {
    const inhaberId = this.action.inhaberId;
    this._store.dispatch(ZahlungActions.resetActualZahlung());
    this._store.dispatch(ZahlungActions.setActualZahlungNull());
    this._router.navigate(['/edit/inhaber/' + inhaberId]);
    this._store.dispatch(ZahlungActions.closeZahlungDeletedDialog());
  }
}

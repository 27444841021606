import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {AppState} from '../../interfaces/app-state.interface';
import {ZahlungActions} from '../../store/actions/zahlung.actions';
import {BelegActions} from '../../store/actions/beleg.actions';
import {BelegSelectors} from '../../store/selectors/beleg.selectors';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'zlng-delete-dialog',
  templateUrl: './beleg-cancelled-dialog.component.html',
  styleUrls: ['./beleg-cancelled-dialog.component.scss']
})
export class BelegCancelledDialogComponent implements OnInit {

  private _store = inject(Store<AppState>);
  private _router = inject(Router);
  private _destroyRef = inject(DestroyRef);

  private _inhaberId = '';
  private _zahlungId = '';

  constructor() {
  }

  ngOnInit(): void {
    this._store.pipe(
      select(BelegSelectors.belegCancelledDialogInhaberId),
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(inhaberId => {
      if (!inhaberId) return;

      this._inhaberId = inhaberId;
    });

    this._store.pipe(
      select(BelegSelectors.belegCancelledDialogZahlungId),
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(zahlungId => {
      if (!zahlungId) return;

      this._zahlungId = zahlungId;
    });
  }

  doOpenEdit(): void {
    this._store.dispatch(ZahlungActions.resetActualZahlung());
    this._router.navigate(['/edit/inhaber/' + this._inhaberId + '/zahlung/' + this._zahlungId]);
    this._store.dispatch(BelegActions.closeBelegCancelledDialog());
    this._store.dispatch(BelegActions.openBelegFilterDialog());
  }

  doDeleteZahlung(): void {
    this._store.dispatch(ZahlungActions.openZahlungDeleteDialog({
      inhaberId: this._inhaberId,
      zahlungId: this._zahlungId,
    }));

    this._store.dispatch(BelegActions.closeBelegCancelledDialog());
  }

  doCloseDialog(): void {
    this._store.dispatch(BelegActions.closeBelegCancelledDialog());
  }
}

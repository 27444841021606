import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {of} from 'rxjs';
import {BelegActions} from '../actions/beleg.actions';
import {BelegDTO, BelegService, BelegSuggestionDTO} from '../../openapi/beleg-openapi';
import {ZahlungActions} from '../actions/zahlung.actions';
import {BelegCancelledDialogComponent} from '../../modules/beleg-cancelled-dialog/beleg-cancelled-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';


@Injectable()
export class BelegEffects {

  private belegCancelledDialogMatDialogRef?: MatDialogRef<BelegCancelledDialogComponent>;

  constructor(
    private actions$: Actions,
    private belegService: BelegService,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
  ) {
  }

  /*
   * INFO: Beleg Vorschlagswerte Laden Effects
   */

  /**
   * Lädt die Vorschlagswerte zu einer BelegId.
   */
  readonly getBelegSuggestions$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(BelegActions.getBelegSuggestions),
        concatMap(action => this.belegService.getBelegSuggestions(
          action.inhaberId,
          action.belegId,
        ).pipe(
          catchError(error => {
            // FIXME Errorhandling
            this.logger.warn('Could not load beleg suggestions', error);

            return error;
          }),
        )),
        map(suggestionsDto => suggestionsDto as BelegSuggestionDTO),
        map(suggestionsDto => ZahlungActions.getBelegSuggestionsSuccess({
          suggestionsDto,
        }))
      )
  );

  /*
   * INFO: Beleg Storniert Actions
   */

  readonly openBelegCancelledDialog$ = createEffect(
    () => this.actions$.pipe(
      ofType(BelegActions.openBelegCancelledDialog),
      tap(() => {
        this.belegCancelledDialogMatDialogRef = this.dialog.open(
          BelegCancelledDialogComponent,
          {
            disableClose: true,
            autoFocus: false,
          }
        );
      }),
    ), {dispatch: false}
  );

  readonly closeBelegCancelledDialog$ = createEffect(
    () => this.actions$.pipe(
      ofType(BelegActions.closeBelegCancelledDialog),
      tap(() => {
        this.belegCancelledDialogMatDialogRef?.close();
      }),
    ), {dispatch: false}
  );

  /*
   * INFO: Beleg Preview Actions
   */

  readonly loadContentById$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(BelegActions.loadContentById),
        concatMap(action => this.belegService.getBeleg(action.inhaberId, action.belegId).pipe(
          map(beleg => {
            return {
              beleg,
              closeable: action.closeable
            };
          })
        )),
        map(belegInfo => {
          const belegDto = belegInfo.beleg;
          const closeable = belegInfo.closeable;
          return BelegActions.loadContent({
            belegDto,
            closeable,
          });
        }),
      )
  );

  readonly loadContent$ = createEffect(
    () => this.actions$
      .pipe(
        // INFO: Content laden; Infos über den zuletzte geladenen Beleg laden
        ofType(BelegActions.loadContent),

        // INFO: Lade Beleg herunter
        concatMap(action => {
          const belegDto = action.belegDto;

          return this.getBelegContent(belegDto);
        }),
        map(belegInfo => {
          if (!belegInfo) {
            return BelegActions.closeBelegPreview();
          }

          const belegDto = belegInfo.beleg;
          const content = belegInfo.content;
          return BelegActions.contentLoaded({
            belegDto,
            content,
          });
        }),
      )
  );

  readonly loadIframeContent$ = createEffect(
    () => this.actions$
      .pipe(
        // INFO: Content des im iFrame anzuzeigenden Belegs laden
        ofType(BelegActions.loadIframeContent),

        // INFO: Lade Beleg herunter
        concatMap(action => {
          const beleg = action.belegDto;

          return this.getBelegContent(beleg);
        }),
        map(belegInfo => {
          if (!belegInfo) {
            return BelegActions.closeBelegPreview();
          }

          const belegDto = belegInfo.beleg;
          const content = belegInfo.content;
          return BelegActions.iFrameContentLoaded({
            belegDto,
            content,
          });
        }),
      )
  );

  /**
   * Lädt den Content zu dem übergebenen BelegDTO.
   *
   * @param beleg Der Beleg dessen Content geladen werden soll
   * @private
   */
  private getBelegContent(beleg: BelegDTO) {
    return this.belegService.getBelegContent(
      beleg.inhaberId,
      beleg.id,
    ).pipe(
      catchError(error => {
        this.logger.error('Could not read beleg content', error);

        let errorMsg = '';
        switch (error.status) {
          case 404: {
            errorMsg = 'Inhaber kann nicht gefunden werden';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden des Belegs';
          }
        }

        this.snackbar.open(errorMsg, undefined, {duration: 5000, panelClass: 'error'});

        return of(error);
      }),
      concatMap(async blob => {
        const status = parseInt(blob.status, 10);
        if (status >= 400) {
          return;
        }

        // INFO: Wandel den blob in base64 string um, damit dieser serializable ist.
        const content = await new Promise(resolve => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.readAsDataURL(blob);
        }) as string;
        return {beleg, content};
      }),
    );
  }

}

import {Component, DestroyRef, inject} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable} from 'rxjs';
import {IconService} from './modules/icon/icon.service';
import {IntentActionService} from './services/intent.action.service';
import {PortalMessageBroker} from './portal/portal-message-broker';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'zlng-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private _iconService = inject(IconService);
  private _intentActionService = inject(IntentActionService);
  private _portalMessageBroker = inject(PortalMessageBroker);
  private _oidcSecurityService = inject(OidcSecurityService);
  private _destroyRef = inject(DestroyRef);

  runningInPortal$: Observable<boolean>;

  constructor() {
    this.runningInPortal$ = this._portalMessageBroker.runningInPortal$.pipe(
      takeUntilDestroyed(this._destroyRef),
    );
  }

  doLogout(): void {
    this._oidcSecurityService.logoff();
  }
}

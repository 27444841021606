import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {filter} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import {IntentActionService} from '../../services/intent.action.service';
import {AppState} from '../../interfaces/app-state.interface';
import {ZahlungActions} from '../../store/actions/zahlung.actions';
import {ZahlungSelectors} from '../../store/selectors/zahlung.selectors';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'zlng-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  private _store = inject(Store<AppState>);
  private _route = inject(ActivatedRoute);
  private _intentActionService = inject(IntentActionService);
  private _destroyRef = inject(DestroyRef);

  private inhaberId = '';
  private zahlungId = '';

  public showDeleteWrapper = false;
  public paymentDeleteFailureInfo = '';

  constructor() {
  }

  ngOnInit(): void {
    this._store.pipe(
      select(ZahlungSelectors.zahlungDeleteDialogInhaberId),
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(inhaberId => {
      if (!inhaberId) return;

      this.inhaberId = inhaberId;
    });

    this._store.pipe(
      select(ZahlungSelectors.zahlungDeleteDialogZahlungId),
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(zahlungId => {
      if (!zahlungId) return;

      this.zahlungId = zahlungId;
    });

    this._store.pipe(
      select(ZahlungSelectors.zahlungDeleteSuccessful),
      filter(value => value !== undefined),
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(success => {
      if (success) {
        this.closeDialog();
      } else {
        this.showDeleteWrapper = true;
      }
    });

    this._store.pipe(
      select(ZahlungSelectors.zahlungDeleteFailureInfo),
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(info => {
      if (!info) return;

      this.paymentDeleteFailureInfo = info;
    });

    this._store.select(ZahlungSelectors.zahlungDeleteDialogOpen).pipe(
      filter(isOpen => !isOpen),
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(isOpen => {
      if (!isOpen) {
        if (this._intentActionService.isRunningInPortal()) {
          this._intentActionService.doCloseDialogV1();
        }
      }
    });

    this._route.paramMap.pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(paramMap => {
      const inhaberId = paramMap.get('inhaberId') || '';
      const zahlungId = paramMap.get('zahlungId') || '';
      if (inhaberId && zahlungId) {
        this._store.dispatch(ZahlungActions.updateZahlungDeleteDialogData({
          inhaberId,
          zahlungId,
        }));
      }
    });
  }

  deleteZahlung(): void {
    this._store.dispatch(ZahlungActions.zahlungDelete({
      inhaberId: this.inhaberId,
      zahlungId: this.zahlungId,
    }));
  }

  closeDialog(): void {
    this._store.dispatch(ZahlungActions.closeZahlungDeleteDialog());
  }
}

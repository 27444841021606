<div class="info-wrapper">
  <div class="title">Beleg zwischenzeitlich storniert</div>
  <div class="message">
    Bitte wähle, ob die Zahlung gelöscht oder ein neuer Beleg zugeordnet werden soll.
  </div>
  <div class="button-wrapper">
    <jf-button
      [variant]="'primary'"
      (doClick)="doOpenEdit()"
    >
      Neu zuordnen
    </jf-button>

    <jf-button
      [variant]="'outline'"
      (click)="doDeleteZahlung()"
    >
      Löschen
    </jf-button>

    <jf-button
      [variant]="'ghost'"
      (doClick)="doCloseDialog()"
    >
      Abbrechen
    </jf-button>
  </div>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeletedDialogComponent} from './deleted-dialog.component';
import {ButtonModule} from '@adnova/jf-ng-components';


@NgModule({
  declarations: [
    DeletedDialogComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
  ]
})
export class DeletedDialogModule {
}

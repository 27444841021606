<div class="info-wrapper" *ngIf="!showDeleteWrapper">
  <div>
    <div class="title">Zahlung löschen</div>
    <div class="message">
      Soll die Zahlung wirklich gelöscht werden?<br>
      Dieser Vorgang kann nicht rückgängig gemacht werden.
    </div>
    <div class="button-wrapper">
      <jf-button
        [variant]="'primary'"
        (doClick)="deleteZahlung()"
      >
        Ja, Zahlung löschen
      </jf-button>
      <jf-button
        [variant]="'ghost'"
        (doClick)="closeDialog()"
      >
        Abbrechen
      </jf-button>
    </div>
  </div>
</div>

<div class="error-wrapper" *ngIf="showDeleteWrapper">
  <div>
    <div class="title">Zahlung löschen</div>
    <div class="message" [innerHTML]="'Diese Zahlung konnte nicht gelöscht werden' + paymentDeleteFailureInfo"></div>
    <div class="link">
      Problem besteht weiterhin?<br>
      <a href="mailto:service@just-farming.de" class="email">Just-Farming Benutzerservice kontaktieren.</a>
    </div>
    <div class="button-wrapper">
      <jf-button
        [variant]="'primary'"
        (doClick)="closeDialog()"
      >
        Schließen
      </jf-button>
    </div>
  </div>
</div>

<div class="info-wrapper">
  <div>
    <div class="title">Zahlung wurde zwischenzeitlich gelöscht</div>
    <div class="message">
      Möchtest du die Überweisung trotzdem ausführen, lege bitte eine neue Überweisung an.
    </div>
    <div class="button-wrapper">
      <jf-button
        [variant]="'primary'"
        (doClick)="backToOverview()"
      >
        Zurück zur Zahlungsübersicht
      </jf-button>

      <jf-button
        [variant]="'ghost'"
        (doClick)="newPayment()"
      >
        Neue Überweisung
      </jf-button>
    </div>
  </div>
</div>
